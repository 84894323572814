import React, { useState } from 'react';
import { socialMediaTransparent } from '@/components/Footer/utils';
import Layout from '@/components/Layout';
import PageHeader from '@/components/PageHeader';
import { Typography } from '@/components/Typography';
import { trackEvent } from '@/helpers/analytics';
import {
  ConnectWithAkuityIcon,
  ReadTheAkuityBlogIcon,
  ReadTheAkuityDocsIcon,
  StartFreeTrialIcon,
  TalksFromAkuityIcon
} from '@/static/assets/icons';
import './thanks.scss';
import { seoConfiguration } from '@/utils/seo-config';

const ThankYouPage = () => {
  const [showSocialMediaIcon, setShowSocialMediaIcon] = useState(false);

  return (
    <Layout {...content.seo}>
      <div className="thank-you-page-wrapper">
        <PageHeader
          textPrimary={{
            text: content.header,
            color: 'akuity-purple'
          }}
          textDescription={{
            text: content.subheader
          }}
        />

        <div className="helper-cards-wrapper row">
          {content.links.map((item, idx) => (
            <div key={idx} className="helper-card-wrapper col-md-6 col-lg-4">
              <a
                href={item.link}
                // @ts-ignore
                target={item.isExternalLink ? '_blank' : ''}
                rel="noreferrer"
                onClick={() => {
                  setShowSocialMediaIcon(true);
                  item.onClick?.();
                }}
              >
                <div className="helper-card">
                  <item.icon />
                  <Typography
                    component="p"
                    typographyKind="text-primary-medium-regular"
                  >
                    {item.text}
                  </Typography>
                </div>
              </a>
              {item.isSocialMedia && showSocialMediaIcon && (
                <div className="social-media-icons">
                  {socialMediaTransparent.map((icon) => (
                    <a
                      key={icon.id}
                      target="_blank"
                      rel="noreferrer"
                      href={icon.url}
                    >
                      <icon.icon />
                    </a>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

const content = {
  seo: seoConfiguration.thanks,
  header: 'Thanks for contacting Akuity!',
  subheader:
    'We’ll be connecting soon! In the meantime, here are ways to learn more and get started.',
  links: [
    {
      icon: StartFreeTrialIcon,
      link: '/signup',
      text: 'Start a free trial of Akuity',
      isExternalLink: true,
      onClick: () => trackEvent('akuity_io_to_cloud')
    },
    {
      icon: ReadTheAkuityBlogIcon,
      link: '/blog/',
      text: 'Read the Akuity Blog'
    },
    {
      icon: ReadTheAkuityDocsIcon,
      link: 'https://docs.akuity.io/',
      text: 'Read the Akuity Docs',
      isExternalLink: true
    },
    {
      icon: TalksFromAkuityIcon,
      link: '/resources/',
      text: 'Talks and Webinars from Akuity'
    },
    {
      icon: ConnectWithAkuityIcon,
      text: 'Connect with Akuity on social media',
      isSocialMedia: true
    }
  ]
};

export default ThankYouPage;
